import pageNavigator from "../pageNavigator/pageNavigator"

import { h, text } from "hyperapp"
import html from 'hyperlit'

const exhibitions2 = (state) => {
  return html`
  <div class="leftbioside">
  <h2>Gruppenausstellungen (Auswahl) <br/><i>Selected Group Exhibitions</i></h2>
  ${pageNavigator(state)}
  <section class="bio">
    <div class="year">
      <h3>2019</h3>
      <p>Editionen, Galerie Halle, Linz</p>
    </div>
    <div class="year">
      <h3>2018</h3>
      <p>DECLARED, Galerie Rauminhalt, Wien <br/>wer war 1968? Lentos Kunstmuseum, Linz <br/>schluss mit der wirklichkeit!, Oö. Landesgalerie, Linz</p>
    </div>
    <div class="year">
      <h3>2017</h3>
      <p>photo::vienna, Werkschau, MAK, Wien</p>
    </div>
    <div class="year">
      <h3>2015</h3>
      <p>summer show, viertelneun gallery, Wien</p>
    </div>
    <div class="year">
      <h3>2014</h3>
      <p>art fair cologne, Köln, mit viertelneun gallery, Wien</p>
    </div>
    <div class="year">
      <h3>2013</h3>
      <p>NEW FRONTIERS. Zeichnen, Architekturzentrum Wien <br/>in between, Galerie Z, Bregenz <br/>Die Siebziger Jahre, Expansion der Wiener Kunst, MUSA, Wien</p>
    </div>
    <div class="year">
      <h3>2011</h3>
      <p>Absolut Wien, Wien Museum</p>
    </div>
    <div class="year">
      <h3>2008</h3>
      <p>Kunst und Politik, MUSA, Wien</p>
    </div>
    <div class="year">
      <h3>2008</h3>
      <p>19682008, Kunstverein Kärnten, Künstlerhaus Klagenfurt</p>
    </div>
    <div class="year">
      <h3>2002</h3>
      <p>Timo Huber, Viola..., Galerie Chobot, Wien, im Rahmen von Kontext: Krieg, Künstlerische Statements zum Thema „Gewalt“</p>
    </div>
    <div class="year">
      <h3>1994</h3>
      <p>Signale/Haltestellen, Rübenzählplatz, Wien</p>
    </div>
    <div class="year">
      <h3>1986</h3>
      <p>Grenzbereiche der Fotografie, Fotogalerie WUK, Wien</p>
    </div>
    <div class="year">
      <h3>1982</h3>
      <p>Grupa „Junij“, Beograd, Jugoslawien</p>
    </div>
    <div class="year">
      <h3>1981</h3>
      <p>Volkshalle, Rathaus, Wien <br/>Vier nach Anker, Remise Koppreitergasse, Wien</p>
    </div>
    <div class="year">
      <h3>1979</h3>
      <p>Präsentation, Museum UCLA, Los Angeles, USA</p>
    </div>
    <div class="year">
      <h3>1978</h3>
      <p>fantastic photography in Europe (2), u. a. Palais des Beaux Arts, Brüssel; Lith. <b/>Photogr. Soc. Vilnius; Fotoforum Kassel <br/>Tendenzen und Wege, Secession, Wien</p>
    </div>
    <div class="year">
      <h3>1977</h3>
      <p>International Drawing Biennale, Cleveland, GB <br/>Reflexions, Canon Photo Gallery, Amsterdam</p>
    </div>
    <div class="year">
      <h3>1976/77</h3>
      <p>fantastic photography in Europe (1), u. a. 7. Rencontres Internationales de la Photographie, Arles; Frans Hals Museum, Haarlem; Galleria Rotonda, Mailand; <br/>Fotografiska Museet, Stockholm; Maison Europeene de la Photographie, Chalon; Festival d´Automne, Paris</p>
    </div>
    <div class="year">
      <h3>1976</h3>
      <p>Olympische Spiele, Galerie Yppen, Wien</p>
    </div>
    <div class="year">
      <h3>1975</h3>
      <p>Oberösterreichische Avantgarde, Neue Galerie der Stadt Linz und Künstlerhaus, Wien <br/>Medium Photographie, Kulturamt der Stadt Wien <br/>Optische Umweltanalysen, UD-Galerie, Stuttgart <br/>Herbstsalon, Galerie St. Stephan, Wien <br/>Weihnachtssalon, Galerie in der GGK, Wien</p>
    </div>
    <div class="year">
      <h3>1974</h3>
      <p>Art Basel 5´74, Basel, mit Galerie Die Brücke, Wien <b/>Timo Huber & Heinzi Leitner, Small Visions, Galerie 29, Wien</p>
    </div>
    <div class="year">
      <h3>1973</h3>
      <p>Art Basel 4\`73, Basel, mit Galerie Die Brücke, Wien <br/>IKI 73, Düsseldorf, mit Galerie Die Brücke, Wien <br/>Gli habiti del imperatore, Galleria Luca Pallazzoli, Mailand</p>
    </div>
    <div class="year">
    <h3>1972</h3>
    <p>9. Internationale Biennale, Menton <br/>Nicht Secessionisten in der Secession, Secession, Wien <br/>Realismus heute. Bilder Zeichnungen Objekte, Galerie Schottenring, Wien</p>
  </div>
  </section>
  <div class="imagediv" >
  <img src=${state.page3Image} alt="work of Timo Huber" />
  <label>in resistance, Mischtechnik, <i>Mixed media</i>,  2020</label>
  </div>
  ${pageNavigator(state)}

  </div>
  `
}

export default exhibitions2