import pageNavigator from "../pageNavigator/pageNavigator"

import { h, text } from "hyperapp"
import html from 'hyperlit'

const bio = (state) => {
  return html`
  <div class="leftbioside">
  <h2>Timo Huber</h2>
  ${pageNavigator(state)}
  <section class="bio">
    <div class="year">
      <h3>1944</h3>
      <p>geboren <i>born</i> in Freistadt / <i>OÖ Upper Austria</i><br/>Studium der Architektur an der <i>Study of architecture</i> at TH / TU Wien</p>
    </div>
    <div class="year">
      <h3>1967</h3>
      <p>Mitarbeit bei Aktionen und Filmen der Wiener Aktionisten <i>Participation in actions and films of the Viennese Actionists</i></p>
    </div>
    <div class="year">
      <h3>1969</h3>
      <p>Gründungsmitglied der Architekturgruppe <i>Founding member of the architecture group Zünd-Up</i> (1969-72)</p>
    </div>
    <div class="year">
      <h3>1970</h3>
      <p>Gründungsmitglied der Architekturgruppe <i>Founding member of the architecture group Salz der Erde</i> (1970-72)</p>
    </div>
    <div class="year">
      <h3>1988-2019</h3>
      <p>eigenes Architekturbüro <i>own architecture office in <i>Vienna</i> / Wien</i></p>
    </div>
    <div class="year">
      <h3>2015</h3>
      <p>Goldenes Ehrenzeichen der Stadt Wien</p>
    </div>
    <div>
    <h3 className="currentLiving" >Lebt und arbeitet <i>Lives and works in Vienna</i> / Wien</h3>
    </div>
  </section>
  <div class="imagediv" >
  <img src=${state.page1Image} alt="work of Timo Huber" />
  <label>heavy clouds, Montage, 2020</label>
  </div>
  ${pageNavigator(state)}
  </div>
  `
}

export default bio