import { setLandingFalse } from "./landingActions"
import { h, text } from "hyperapp"
import html from 'hyperlit'

const landing = (props) => {
  return html`
  <div class="landing">
  <img src=${props.landingImage} alt="Foto vom Künstler, Timo Huber" />
  <button onclick=${setLandingFalse} >TIMO HUBER</button>
  </div>
  `
}

export default landing