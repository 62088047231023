
import { h, text } from "hyperapp"
import html from 'hyperlit'
import { goBack, goForward } from "./navigatorActions"

const pageNavigator = (state) => {
  return html`
  <div class="navigation">
  ${
    html`<button class="pageback" onclick=${goBack}><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-left" width="48" height="48" viewBox="0 0 24 24" stroke-width="1.5" stroke="#A6373F" fill="none" stroke-linecap="round" stroke-linejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
    <polyline points="15 6 9 12 15 18" />
    </svg></button>`
  }
  ${
    state.page !== (state.totalPages -1) ?
    html`<button class="pageforward" onclick=${goForward}><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-right" width="48" height="48" viewBox="0 0 24 24" stroke-width="1.5" stroke="#A6373F" fill="none" stroke-linecap="round" stroke-linejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
    <polyline points="9 6 15 12 9 18" />
  </svg></button>`
    : null
  }
  </div>
  `
}

export default pageNavigator