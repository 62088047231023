import pageNavigator from "../pageNavigator/pageNavigator"

import { h, text } from "hyperapp"
import html from 'hyperlit'

const exhibitions1 = (state) => {
  return html`
  <div class="leftbioside">
  <h2>Einzelausstellungen und Performances (Auswahl) <br/><i>Selected Solo Exhibitions</i></h2>
  ${pageNavigator(state)}
  <section class="bio">
    <div class="year">
      <h3>2019</h3>
      <p>Timo Huber. Interventionen, Fotomontagen und Zeichnungen, GALERIE-halle, Linz</p>
    </div>
    <div class="year">
      <h3>2018</h3>
      <p>Timo Huber im Salon 44/21, Salon 44/21, Wien</p>
    </div>
    <div class="year">
      <h3>2016</h3>
      <p>Timo Huber. Zwischenstand, Fotomontagen und Zeichnungen, Studio Präsentation, Wien</p>
    </div>
    <div class="year">
      <h3>2015</h3>
      <p>Timo Huber. Yesternow, Präsentation und Diskussion, Salotto Vienna, Seestadt Aspern, Wien</p>
    </div>
    <div class="year">
      <h3>2014</h3>
      <p>Timo Huber. Confrontation, Montagen und Zeichnungen, viertelneun gallery, Wien</p>
    </div>
    <div class="year">
      <h3>2012</h3>
      <p>Timo Huber. Traumbeute, Galerie Z, Bregenz</p>
    </div>
    <div class="year">
      <h3>2011</h3>
      <p>Timo Huber, Zeichnungen und Montagen / Piotr Janowski, Paintings, Studiopräsentation, Wien<br/>Timo Huber. Wildwechsel, Montagen und Zeichnungen, Kunstraum Dr. David, Wien</p>
    </div>
    <div class="year">
      <h3>2010</h3>
      <p>Timo Huber. new work, Fotomontagen, Galerie 49/1, Wien, Monat der Fotografie<br/>Timo Huber. Cross over, Montagen und Zeichnungen, Künstlerhaus Wien</p>
    </div>
    <div class="year">
      <h3>2008</h3>
      <p>Timo Huber. Schnittstellen, Fotomontagen, Galerie 49/1, Wien, Monat der Fotografie</p>
    </div>
    <div class="year">
      <h3>2007</h3>
      <p>Timo Huber. schichten, Montagen und Zeichnungen, Galerie Denkraum, Wien</p>
    </div>
    <div class="year">
      <h3>2006</h3>
      <p>Timo Huber. Zeichnungen und Montagen, Club an der Grenze, Windisch-Minihof, Burgenland</p>
    </div>
    <div class="year">
      <h3>2004</h3>
      <p>Timo Huber. short cut, Fotomontagen und Zeichnungen, Making-it-Zentrum, Wien <br/>Timo Huber. echo:lot, Galerie Halle, Linz</p>
    </div>
    <div class="year">
      <h3>2003</h3>
      <p>Timo Huber. CRItiX(+), Soho in Ottakring/IP Two, Wien</p>
    </div>
    <div class="year">
      <h3>2002</h3>
      <p>Timo Huber. Cut, Zeichnungen – Fotomontagen, Galerie Chobot, Wien</p>
    </div>
    <div class="year">
      <h3>2000</h3>
      <p>Timo Huber. Fotomontagen, R. Horn´s, Wien</p>
    </div>
    <div class="year">
      <h3>1999</h3>
      <p>Timo Huber. Look, Instant Galerie, Atelier Grünangergasse, Wien</p>
    </div>
    <div class="year">
      <h3>1997</h3>
      <p>Timo Huber. Der graue Rabe fliegt, Ausgewählte Arbeiten, Galerie Der graue Rabe fliegt, Wien</p>
    </div>
    <div class="year">
      <h3>1988</h3>
      <p>Timo Huber. Kaltes Buffet, Ausstellung und Performance (Irene Faendrich, Dance; <br/>Hannes Gottschlich, Slow Table), Skala, Wien</p>
    </div>
    <div class="year">
      <h3>1986</h3>
      <p>Timo Huber. Wortlust – Funken. Performance (Robert Bilek, Sax; Sandra Kreisler, Voc), VHS Margareten, Wien <br/>Timo Huber. Rome-o und Juli-a, Performance (Irene Faehndrich, Dance; Sandra Kreisler, Voc; Thomas Jank, Axe, Saw), W.U.K, Wien</p>
    </div>
    <div class="year">
      <h3>1985</h3>
      <p>Timo Huber, Kiai – Muskelton, Textperformance mit zwei Karate-Akteuren und Musik (Walter Malli, Sax), W.U.K.-Werkstätten und Kulturhaus, Wien</p>
    </div>
    <div class="year">
      <h3>1984</h3>
      <p>Timo Huber, Viel Goldspeck, Szenische Lesung mit Musik (Performance), (Streichquartett: Gruppe M.H.S.K.), Kunstverein Wien / Alte Schmiede, Wien <br/>Timo Huber – Barabbas, Bitteres Öl – Windwort, Textperformance mit Musik, Sound (Claus Barabbas, Sax), Kulturzentrum Zeltgasse, Wien</p>
    </div>
    <div class="year">
      <h3>1983</h3>
      <p>Timo Huber, Fettlauf 3, Performance und Ausstellung, Secession, Wien <br/>Timo Huber, Fettlauf 4, Textperformance und Ausstellung, Galerie Armstorfer, Salzburg</p>
    </div>
    <div class="year">
      <h3>1982</h3>
      <p>Timo Huber, Fettlauf 1, Opfer und Wandlung, Ausstellung und Performance, Dramatisches Zentrum, Wien <br/>Timo Huber, Fettlauf, Opfer und Wandlung, Performance und Projektionen, Filmhaus Stöbergasse, Wien</p>
    </div>
    <div class="year">
      <h3>1981</h3>
      <p>Timo Huber. Affenbrot, Performance und Ausstellung, Kulturzentrum Maria Schutz, Nö</p>
    </div>
    <div class="year">
      <h3>1980</h3>
      <p>Timo Huber. Montagen und Zeichnungen, Galerie Armstorfer, Salzburg <br/>Timo Huber. Co-Merz, Kulturzentrum Minoriten, Graz <br/>Timo Huber. Ausschnitte, Galerie an der Stadtmauer, Städtisches Kulturzentrum Villach</p>
    </div>
    <div class="year">
      <h3>1978</h3>
      <p>Galerie Mettler, St. Gallen, Schweiz <br/>Galerie Kunstwerk, Wien</p>
    </div>
    <div class="year">
      <h3>1977</h3>
      <p>Timo Huber, Zeichnungen aus Mexiko und Wien, Galerie Hildebrand, Klagenfurt photomontages politiques de Timo Huber, Galerie Contrejour, Paris <br/>Timo Huber. Cuautemoc, Landeskulturzentrum Ursulinenhof, Linz <br/>Canon Photo Gallery, Amsterdam </p>
    </div>
    <div class="year">
      <h3>1976</h3>
      <p>Timo Huber. Umweltbetrachtung, UD-Galerie, Stuttgart</p>
    </div>
    <div class="year">
      <h3>1975</h3>
      <p>Timo Huber – Montagen, Galerie Lehner, Linz</p>
    </div>
    <div class="year">
      <h3>1973</h3>
      <p>Timo Huber. Montaze, Österreichisches Kulturzentrum, Warschau <br/>Timo Huber: Montagen, Österreichisches Bauzentrum Palais Liechtenstein, Wien</p>
    </div>
    <div class="year">
      <h3>1972</h3>
      <p>Spiegel des großen Welttheaters, Neue Galerie der Stadt Linz <br/>Proklamation (mit Othmar Zechyr), Secession, Wien (Performance)</p>
    </div>
    <div class="year">
      <h3>1971</h3>
      <p>Bilder aus der heilen Welt, Galerie Die Brücke, Wien</p>
    </div>
  </section>
  <div class="imagediv" >
  <img src=${state.page2Image} alt="work of Timo Huber" />
  <label>tumbling blocks, Montage, 2020</label>
  </div>
  ${pageNavigator(state)}

  </div>
  `
}

export default exhibitions1