import { h } from "hyperapp"
import html from 'hyperlit'
import bio from "./bio/bio"
import exhibitions1 from "./bio/exhibitions1"
import exhibitions2 from "./bio/exhibitions2"

import contactPage from "./contact/contactPage"
import landing from "./landing/landing"


const view = (state) => {

  if (state.landingPage) {
    return html`
    ${landing(state)}
    `
  }

  switch (state.page) {
    case 0:
      return html`
      <div class="p1">
      <span class="construction" >WEBSEITE IN ARBEIT</span>
      ${bio(state)}
      </div>
      `
    case 1:
      return html`
      <div class="p2">
      <span class="construction" >WEBSEITE IN ARBEIT</span>
      ${exhibitions1(state)}
      </div>
      `
    case 2:
      return html`
      <div class="p3">
      <span class="construction" >WEBSEITE IN ARBEIT</span>
      ${exhibitions2(state)}
      </div>
      `
    case 3:
      return html`
      <div class="p4">
      <span class="construction" >WEBSEITE IN ARBEIT</span>
      ${contactPage(state)}
      </div>
      `
  }
}


export default view