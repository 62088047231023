import { h, text } from "hyperapp"
import html from 'hyperlit'
import pageNavigator from "../pageNavigator/pageNavigator"

const contactPage= (state) => {
  return html`
  <div class="leftbioside">
  <h2>Kontakt <br/><i>Contact</i></h2>
  ${pageNavigator(state)}
  <section class="contact">
  <h3>Timo F. Huber<span>Arch. DI</span></h3>
  <address>
  <div>
  <label>Ertlgasse 4/14</label>
  <label>1010 Wien | Vienna | Austria</label>
  </div>
  <div>
  <a href="tel:+436765710206">+43 676 5710206</a>
  <a href="mailto:timo.huber@huber-zt.at">timo.huber@huber-zt.at</a>
  <a href="http://www.zuend-up.com">zuend-up.com</a>
  </div>
  </address>
  <label>Web Entwickler <a href="https://www.nicolaurlicic.com" target="_blank" rel="noopener noreferrer">Nicola Urlicic</a></label>

  </section>
  <div class="imagediv" >
  <img src=${state.page4Image} alt="work of Timo Huber" />
  <label>portrait of thoughts, Zeichnung, <i>drawing</i>, 2020</label>
  </div>
  ${pageNavigator(state)}

  </div>
  `
}

export default contactPage