import timo from "../img/optimized/landing2.0.jpg"
import p1 from "../img/optimized/PAGE1.jpg"
import p2 from "../img/optimized/PAGE2.jpg"
import p3 from "../img/optimized/PAGE3.jpg"
import p4 from "../img/optimized/PAGE4.jpg"

const init = {
  landingImage: timo,
  page1Image: p1,
  page2Image: p2,
  page3Image: p3,
  page4Image: p4,
  landingPage: true,
  page: 0,
  totalPages: 4
}

export default init