export const goForward = (state) => {
  return { ...state, page: state.page +1 }
}

export const goBack = (state) => {
  if (state.page === 0) {
    return { ...state, landingPage: true }
  } else {
    return { ...state, page: state.page -1 }
  }
}